import styled, {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`

  * {
   
    box-sizing: content-box;
    font-family: "Roboto", sans-serif;
  }
`
export default GlobalStyle