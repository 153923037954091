import React from "react";
import ApiDateAnalytics from "./Readme/readme";
import GlobalStyle from "./GlobalStyles";


function App(){

  return (
    <>
    <GlobalStyle />
    <ApiDateAnalytics />
 </>
  )
}

export default App